import {DateTime} from 'luxon';
import {parseDateTime} from '@matchsource/utils';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_FORMAT_MONTH_YEAR = 'MM/yyyy';
export const DATE_FORMAT_ISO = 'yyyy-MM-dd';
export const DATE_FORMAT_FULL_MONTHYEAR = 'MMMM yyyy';
export const TIMEZONE_OFFSET_IN_MINUTES: number = new Date().getTimezoneOffset();

export const DATE_FORMAT_FULL = 'MMM dd yyyy';
export const DATE_FORMAT_MONTHYEAR = 'MMM yyyy';
const DATE_FORMAT_OLD = 'yyyy-MM-dd';
// eslint-disable-next-line @typescript-eslint/quotes
const DATETIME_FORMAT_UTC = "yyyy-MM-dd'T'HH:mm:ss'Z'";
// eslint-disable-next-line @typescript-eslint/quotes
const DATETIME_FORMAT_UTC_MIDNIGHT = "yyyy-MM-dd'T'00:00:00'Z'";

const MINIMUM_PAST_DATE = '1900-01-01';

export const fromISOToMonthYear = (date: string) =>
  date ? parseDateTime(date, {zone: 'utc'}).toFormat(DATE_FORMAT_MONTH_YEAR) : '';

export const fromMonthYearDateToISO = (date: string, format: string = DATE_FORMAT_MONTH_YEAR) =>
  date ? DateTime.fromFormat(date, format, {zone: 'utc'}).toFormat(DATE_FORMAT_ISO) : '';

export const fromMonthYearDateToDateTimeISO = (date: string, format: string = DATE_FORMAT_MONTH_YEAR) =>
  date ? DateTime.fromFormat(date, format, {zone: 'utc'}).toISO() : '';

export const fromISOToDate = (date: string, format: string = DATE_FORMAT) =>
  date ? DateTime.fromISO(date, {zone: 'utc'}).toFormat(format) : '';

export const fromDateToISO = (date: string, format: string = DATE_FORMAT) =>
  date ? DateTime.fromFormat(date, format, {zone: 'utc'}).toFormat(DATE_FORMAT_ISO) : '';

export const formatDateToFullMonthYear = (date: string) =>
  date ? DateTime.fromISO(date).toFormat(DATE_FORMAT_FULL_MONTHYEAR) : '';

export const isPastMonth = (val: string) => {
  const date = parseDateTime(val).startOf('month');
  const uppderBound = DateTime.now().startOf('month');
  const lowerBound = DateTime.fromISO(MINIMUM_PAST_DATE).startOf('month');
  return date >= lowerBound && date <= uppderBound;
};

export const switchLocalToUtc = (date: Date): Date => new Date(date.getTime() - date.getTimezoneOffset() * 60000);

export const switchUtcToLocal = (date: Date): Date => new Date(date.getTime() + date.getTimezoneOffset() * 60000);

const customFormatDate = (date: string, format = 'MM/dd/yyyy', local = false): string => {
  if (!date) {
    return null;
  }

  if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:?\d{2})$/.test(date)) {
    throw new Error(`${date} has an incorrect format. It should be: yyyy-MM-ddThh:mm:ss(Z|+-0000)`);
  }

  return local ? parseDateTime(date).toFormat(format) : parseDateTime(date, {zone: 'utc'}).toFormat(format);
};

export const formatViewDate = (date: string, format = DATE_FORMAT_FULL, local = false): string => {
  const formattedDate = customFormatDate(date, format, local);
  return formattedDate && formattedDate.toUpperCase();
};

export const formatViewDateLocal = (date: string, format = DATE_FORMAT_FULL) => formatViewDate(date, format, true);

/**
 * Format date string (yyyy-MM-dd) to a format which is supported by luxon.js
 * The date is timezone agnostic. For example: birthday
 *
 * @param date - Date in yyyy-MM-dd format
 * @param format - Formatting rules
 * @return formatted date
 * @throws Will throw an error if date argument has an incorrect format
 */
const formatDateByTemplate = (date: string, format: string): string => {
  if (!date) {
    return '';
  }

  if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    // throw new Error(`"${date}" has an incorrect format. It should be: yyyy-MM-dd`);
    // eslint-disable-next-line no-console
    console.log(`"${date}" has an incorrect format. It should be: yyyy-MM-dd`);
  }

  return parseDateTime(date).toFormat(format).toUpperCase();
};

/**
 * Format datetime string (yyyy-MM-ddThh:mm:ssZ) to a format which is supported by luxon
 * This method convert datetime timezone to browser's one
 *
 * @param dateTime - DateTime in yyyy-MM-ddThh:mm:ssZ format
 * @param format - Formatting rules
 * @return formatted date
 * @throws Will throw an error if date argument has an incorrect format
 */
const formatDateTimeByTemplate = (dateTime: string, format: string): string => {
  if (!dateTime) {
    return '';
  }

  if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:?\d{2})$/.test(dateTime)) {
    // throw new Error(`"${dateTime}" has an incorrect format. It should be: yyyy-MM-ddThh:mm:ss(Z|+-0000)`);

    // This is a temporary solution in scope of MS-13033
    return formatDateByTemplate(dateTime, format);
  }

  return parseDateTime(dateTime).toFormat(format).toUpperCase();
};

export const formatDate = (date: string): string => formatDateByTemplate(date, DATE_FORMAT_FULL);

export const formatDateToMonthYear = (date: string): string => formatDateByTemplate(date, DATE_FORMAT_MONTHYEAR);

export const formatDateTimeToDate = (date: string): string => formatDateTimeByTemplate(date, DATE_FORMAT_FULL);

/**
 * Prepare datetime before sending to a server
 *
 * @param date
 */
export const toDateTime = (date: string | number): string =>
  date ? parseDateTime(date, {zone: 'utc'}).toFormat(DATETIME_FORMAT_UTC) : '';

/**
 * Prepare date before sending to a server
 *
 * @param date
 */
export const toDate = (date: string | DateTime): string => parseDateTime(date).toFormat(DATE_FORMAT_OLD);

export const toTimestamp = (date: string): number => (date ? parseDateTime(date).valueOf() : NaN);

export const toUtcMidnight = (date: string): string =>
  date ? parseDateTime(date).toFormat(DATETIME_FORMAT_UTC_MIDNIGHT) : '';

export const formatUtcToLocalDate = (date: string): string => parseDateTime(date).toUTC().toFormat(DATE_FORMAT_OLD);

/* TEST:START */
export {formatDateByTemplate, formatDateTimeByTemplate};
/* TEST:END */
